import React, { useContext } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import { getImgixUrl } from '../../../../helpers';
import { SiteBuilderModule, StyledSVGEmbed } from '../sharedComponents';
import { TestimonialDefaultStyle } from './displayStyles/TestimonialDefaultStyle';
import { ModuleWrapper } from './styles';

const TestimonialModule = (props) => {
  const {
    section: {
      link: button = {},
      background: { path: backgroundImagePath } = {},
      style: sectionBgBrand,
      testimonials = [],
      rotateType,
    } = {},
    pageConfig: { tenantId } = {},
  } = props;

  const themeConfig = useContext(ThemeContext);
  const { testimonialModule } = themeConfig.siteBuilderModules;
  const vStyles = testimonialModule.visualStyles[sectionBgBrand];
  const dStyles = testimonialModule.displayStyles.DEFAULT;

  const { backgroundSVGConfig: { settings: { path, color1, color2 } } = {} } =
    vStyles;

  const backgroundImage = getImgixUrl(tenantId, backgroundImagePath);

  return (
    <SiteBuilderModule
      section={props.section}
      moduleTheme={{ dStyles, vStyles }}
    >
      <ModuleWrapper
        backgroundImage={backgroundImage || ''}
        data-testid="testimonial-module-wrapper"
      >
        {path && (
          <div data-testid="testimonial-module-svg">
            <StyledSVGEmbed href={path} colors={{ color1, color2 }} />
          </div>
        )}
        <TestimonialDefaultStyle
          rotateType={rotateType}
          testimonials={testimonials}
          button={button}
        />
      </ModuleWrapper>
    </SiteBuilderModule>
  );
};

TestimonialModule.propTypes = {
  section: PropTypes.shape({
    background: PropTypes.shape({
      path: PropTypes.string,
    }),
    style: PropTypes.oneOf(['PRIMARY', 'SECONDARY', 'WHITE']),
    testimonials: PropTypes.arrayOf(PropTypes.object),
    link: PropTypes.shape({
      shown: PropTypes.bool,
      type: PropTypes.string,
    }),
  }).isRequired,
  pageConfig: PropTypes.shape({
    tenantId: PropTypes.string,
  }),
};

const mapStateToProps = (state) => ({
  pageConfig: state.pageConfig,
});

export default connect(mapStateToProps)(TestimonialModule);
